export const WEBSITE_NAME = "DailyGrow";
export const WEBSITE_URL = "https://dailygrowth01.online/";
export const API_ACCESS_URL = "https://api.dailygrowth01.online/";
export const PG_ACCESS_URL = "https://dailygrowth01.online/pg/";
export const LOGIN_REDIRECT_URL = WEBSITE_URL+"login";
export const HOME_REDIRECT_URL = WEBSITE_URL+"home";
export const LIVE_CHAT_URL = "https://tawk.to/chat/6710b3832480f5b4f58ee418/1iacjqerk";

export function redirectTo(url) {
    window.location.replace(url);
}

export function openNewPage(url) {
    window.location.href =url;
}

export function getURLParam(param) {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(param);
}

export function getInternetStatus(){
    if(navigator.onLine){
        return true;
    }else{
        return false;
    }
}

export function copyText(text){
    navigator.clipboard.writeText(text);
    return true;
}