import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import PurchaseDialog from '../dialogs/PurchaseDialog';
import ToastDialog from '../dialogs/ToastDialog';
import RewardOptions from '../other-components/RewardOptions';
import { setCookie, getCookie, deleteCookie } from '../modals/Cookie';
import { API_ACCESS_URL, LOGIN_REDIRECT_URL, redirectTo } from '../modals/Constants';
import BottomNavbar from '../other-components/BottomNavbar';

function Home() {
  const navigate = useNavigate();
  const [pageConst, setConstants] = useState({
    accountBalance: 0,
    purchaseDialogInvestId: "",
    purchaseDialogTitle: "",
    purchaseDialogDetails: "",
    purchaseDialogHourIncome: "",
    purchaseDialogDailyIncome: "",
    purchaseDialogTotalIncome: "",
    purchaseDialogTotalDays: "",
    purchaseDialogShow: false,
    purchaseDialogTheme: "dlg-thm-green",
    toastMessage: "",
    toastDialogShow: false,
    slideShowImage: "",
    recordList: [],
  });

  const updateToastDialogState = (data, msg) => {
    console.log(data + "," + msg);
    setConstants(previousState => {
      return { ...previousState, toastDialogShow: data }
    });

    setConstants(previousState => {
      return { ...previousState, toastMessage: msg }
    });
  }

  const updatePurchaseDialogState = (e, data, invest_id, title, details, hourly_income, daily_income, total_income, total_days) => {

    if (e != null && e.target.className == "ps-fx h-100vh res-wth z-i--100 bg-l-black bt-dlg activeDialog") {
      setConstants(previousState => {
        return { ...previousState, purchaseDialogShow: false }
      });
    } else if (data == "true") {

      setConstants(previousState => {
        return { ...previousState, purchaseDialogInvestId: invest_id }
      });

      setConstants(previousState => {
        return { ...previousState, purchaseDialogShow: true }
      });

      setConstants(previousState => {
        return { ...previousState, purchaseDialogTitle: title }
      });

      setConstants(previousState => {
        return { ...previousState, purchaseDialogDetails: details }
      });

      setConstants(previousState => {
        return { ...previousState, purchaseDialogHourIncome: hourly_income }
      });

      setConstants(previousState => {
        return { ...previousState, purchaseDialogDailyIncome: daily_income }
      });

      setConstants(previousState => {
        return { ...previousState, purchaseDialogTotalIncome: total_income }
      });

      setConstants(previousState => {
        return { ...previousState, purchaseDialogTotalDays: total_days }
      });


    } else if (data == "dismiss") {
      setConstants(previousState => {
        return { ...previousState, purchaseDialogShow: false }
      });
    }
  }

  function getAccountInfo() {

    const fecthApiData = async (url) => {

      try {
        const res = await fetch(url);
        const data = await res.json();

        if (data.status_code == "success") {
          let accountName = data.data[0]['account_username'];
          let accountMobileNum = data.data[0]['account_mobile_num'];
          let accountBalance = data.data[0]['account_balance'];
          let accountWinningBalance = data.data[0]['account_w_balance'];
          let accountCoinsBalance = data.data[0]['account_c_balance'];
          let accountJoined = data.data[0]['account_joined'];
          let accountWithdrawl = data.data[0]['account_withdrawl'];
          let accountTodayIncome = data.data[0]['account_today_income'];
          let accountInvestmentPrice = data.data[0]['account_investment_price'];
          let accountInvestmentIncome = data.data[0]['account_investment_income'];

          let service_app_status = data.data[0]['service_app_status'];
          let service_min_recharge = data.data[0]['service_min_recharge'];
          let service_min_withdraw = data.data[0]['service_min_withdraw'];
          let service_recharge_option = data.data[0]['service_recharge_option'];
          let service_telegram_url = data.data[0]['service_telegram_url'];
          let service_app_download_url = data.data[0]['service_app_download_url'];
          let slide_show_banner = data.data[0]['slide_show_banner'];

          setCookie("uname", accountName, 30);
          setCookie("balance", accountBalance, 30);
          setCookie("cbalance", accountCoinsBalance, 30);
          setCookie("wbalance", accountWinningBalance, 30);
          setCookie("totalWithdrawl", accountWithdrawl, 30);
          setCookie("todayIncome", accountTodayIncome, 30);
          setCookie("investmentIncome", accountInvestmentIncome, 30);
          setCookie("investmentPrice", accountInvestmentPrice, 30);
          setCookie("minrecharge", service_min_recharge, 30);
          setCookie("minwithdraw", service_min_withdraw, 30);
          setCookie("rechargeoptions", service_recharge_option, 30);
          setCookie("telegramURL", service_telegram_url, 30);
          setCookie("acjoined", accountJoined, 30);
          setCookie("appDownloadURL", service_app_download_url, 30);
          setConstants({ ...pageConst, accountBalance: accountBalance, slideShowImage: slide_show_banner });

          if (service_app_status == "OFF") {
            navigate('/um', { replace: true });
          }
        } else if (data.status_code == "account_error") {
          if (deleteCookie(1)) {
            navigate('/login', { replace: true });
          }
        }

      } catch (error) {
      }
    };


    if (getCookie("uid")) {
      fecthApiData(API_ACCESS_URL + "request-account-info.php?USER_ID=" + getCookie("uid") + "&SECRET_KEY=" + getCookie("secret") + "&APP_VERSION=3");
    } else {
      redirectTo(LOGIN_REDIRECT_URL);
    }

  }

  useEffect(() => {
    getAccountInfo();
  }, []);


  return (
    <div className="v-center">
      <div className="h-100vh res-wth ovf-scrl-y bg-tar-black hide-sb">
        <ToastDialog intentData={pageConst} updateState={updateToastDialogState} />
        <PurchaseDialog intentData={pageConst} updateState={updatePurchaseDialogState} toastUpdate={updateToastDialogState} />

        <div className='row-view bx-shdw-blk bg-tar-black pd-15'>
          <div className='w-70-p h-70-p v-center br-50 bg-white'>
            <img className='h-w-70' src={require("../icons/profile_icon.png")} />
          </div>

          <div className='col-view mg-l-15'>
            <div className='ft-sz-28 cl-white'>{getCookie("uname")}</div>
            <div className='ft-sz-14 cl-white mg-t-5'>Joined: {getCookie("acjoined")}</div>
          </div>
        </div>

        {/* <RewardOptions /> */}

        <div className='col-view pd-20 mg-b-150'>

          <div className='row-view min-h-160 pd-10-45 bg-grad-lgt-drk-blue br-10'>
            <img className='h-w-36' src={require("../icons/wallet_icon.png")} />
            <div className='mg-l-20'>
              <div className='cl-white ft-sz-23 ft-wgt-b'>My Wallet</div>
              <div className='cl-white ft-sz-23 ft-wgt-b mg-t-10'>₹{getCookie("balance")}</div>
            </div>
          </div>

          <div className='row-view min-h-160 pd-10-45 bg-grad-lgt-drk-blue br-10 mg-t-15'>
            <img className='h-w-36' src={require("../icons/rupee_coin_icon.png")} />
            <div className='mg-l-20'>
              <div className='cl-white ft-sz-23 ft-wgt-b'>Todays Earnings</div>
              <div className='cl-white ft-sz-23 ft-wgt-b mg-t-10'>₹{getCookie("todayIncome")}</div>
            </div>
          </div>

          <div className='row-view min-h-160 pd-10-45 bg-grad-lgt-drk-blue br-10 mg-t-15'>
            <img className='h-w-36' src={require("../icons/wallet_icon.png")} />
            <div className='mg-l-20'>
              <div className='cl-white ft-sz-23 ft-wgt-b'>Total Earnings</div>
              <div className='cl-white ft-sz-23 ft-wgt-b mg-t-10'>₹{getCookie("investmentIncome")}</div>
            </div>
          </div>

          <div className='row-view min-h-160 pd-10-45 bg-grad-lgt-drk-blue br-10 mg-t-15'>
            <img className='h-w-36' src={require("../icons/bank_icon.png")} />
            <div className='mg-l-20'>
              <div className='cl-white ft-sz-23 ft-wgt-b'>Total Withdrawl</div>
              <div className='cl-white ft-sz-23 ft-wgt-b mg-t-10'>₹{getCookie("totalWithdrawl")}</div>
            </div>
          </div>

          <div className='row-view min-h-160 pd-10-45 bg-grad-lgt-drk-blue br-10 mg-t-15'>
            <img className='h-w-36' src={require("../icons/total_invest_icon.png")} />
            <div className='mg-l-20'>
              <div className='cl-white ft-sz-23 ft-wgt-b'>Total Investments</div>
              <div className='cl-white ft-sz-23 ft-wgt-b mg-t-10'>₹{getCookie("investmentPrice")}</div>
            </div>
          </div>

        </div>

        <BottomNavbar activeBar="home" />
      </div>
    </div>
  );
}

export default Home;