import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import '../../MainStyle.css';
import TopBar from '../other-components/TopBar';
import ToastDialog from '../dialogs/ToastDialog';
import { API_ACCESS_URL, LOGIN_REDIRECT_URL, redirectTo } from '../modals/Constants';
import { setCookie, getCookie } from '../modals/Cookie';

function About() {
  const [isInputValCorrect, setInValCorrect] = useState(false);
  const [pageConst, setConstants] = useState({
    pageTitle: "About",
    inHelpsupport: "",
    isLoadingShow: false,
    toastDialogShow: false,
    toastMessage: "",
  });

  const updateLoadingStatus = (data) => {
    setConstants(previousState => {
      return { ...previousState, isLoadingShow: data }
    });
  }

  const updateToastDialogState = (data, msg) => {
    setConstants(previousState => {
      return { ...previousState, toastDialogShow: data }
    });

    setConstants(previousState => {
      return { ...previousState, toastMessage: msg }
    });
  }

  const checkForInputVal = (helpsupportdetails) => {
    if (helpsupportdetails != "" && helpsupportdetails != undefined && helpsupportdetails.length >= 3) {
      setInValCorrect(true);
    } else {
      setInValCorrect(false);
    }
  }

  const onInputValChange = (source, data) => {

    if (source == "helpsupportdetails") {
      checkForInputVal(data);

      setConstants(previousState => {
        return { ...previousState, inHelpsupport: data }
      });
    }
  }

  const validateHelpSupport = () => {
    const requestAPI = async (url) => {
      try {
        const res = await fetch(url);
        const data = await res.json();
        console.log(data.data);
        updateLoadingStatus(false);

        if (data.status_code == "success") {
          updateToastDialogState(true, "Form Submitted!");
        } else {
          updateToastDialogState(true, "Something went wrong! Please try again!");
        }

      } catch (error) {
        updateToastDialogState(true, "There was a technical issue! Please try again!");
      }
    };

    if (isInputValCorrect && pageConst.isLoadingShow == false) {
      updateLoadingStatus(true);
      requestAPI(API_ACCESS_URL + "request-helpsupport.php?USER_ID=" + getCookie("uid") + "&DETAILS=" + pageConst.inHelpsupport);
    }
  }

  useEffect(() => {
    if (!getCookie("uid")) {
      redirectTo(LOGIN_REDIRECT_URL);
    }
  }, []);

  return (
    <div className='v-center'>
      <div className="h-100vh pr-v res-wth ovf-scrl-y hide-sb bg-tar-black">
        <TopBar intentData={pageConst} multiBtn={true} multiBtn1="" multiBtn2="" />
        <ToastDialog intentData={pageConst} updateState={updateToastDialogState} />

        <div className="game-manage-view col-view pd-15 mg-t-45">

          <div className='col-view w-100 pd-18 bg-white br-10'>

            <div className='ft-wgt-b'>𝗗𝗔𝗜𝗟𝗬 𝗚𝗥𝗢𝗪</div>

            <div className='mg-t-15'>GET DAILY ROI OF 1% FOR LIFETIME ACCORDING TO YOUR PACKAGES</div>

            <div className='mg-t-15'>Daily ROI will credited to your wallet at 6.00PM Daily
              And get 2 Level income,from Level 1 you will get 10% of their income and from Level 2 you will get 5% of their income.</div>

            <div className='mg-t-15'>After team members invest in the package, you will can get daily rebate income</div>

            <div className='mg-t-15'>Level 1 team: 10%</div>

            <div className='mg-t-15'>Level 2 team: 5%</div>

            <div className='ft-sz-17 mg-t-15'>For example;</div>
            <div className='mg-t-15'>If level 1 get 100₹ Daily ROI you will get 10₹ rebate income from Level 1</div>

            <div className='mg-t-15'></div>If level 2 get 100₹ Daily ROI you will get 5₹ rebate income from Level 2


            <div className='ft-sz-17 mg-t-15'>Notes:-</div>
            <div className='mg-t-15'>Our company invest in stocks, crypto, trading and made profit,it will be split and given to the users</div>

            <div className='mg-t-15'>Previous research shows that individual investors are attracted to stocks with high maximum daily returns in the previous month (MAX). We examine the underlying sources of this preference. In a discrete choice investment experiment, subjects prefer high-MAX stocks only if these stocks are speculative with a comparably high level of return volatility. However, after controlling for volatility, subjects no longer favor high-MAX stocks. Hence, individuals do not prefer higher maximum daily returns per se. We find additional support for these findings in the aggregate trading patterns of Robinhood retail investors.</div>

            <div className='mg-t-15'>Running a business demands dedication, effort, and time to ensure growth and success. However, all your hard work could be in vain if you overlook potential legal risks. A no-responsibility disclaimer is like a life-saving jacket, protecting you from legal liabilities. You can consider it the simplest way to guard yourself against legal hacks. Let us dive in to know more about it!</div>

            <div className='ft-sz-17 mg-t-15'>Disclaimer:</div>
            <div className='mg-t-15'>The information, software, products, and services included in or available through the  sites/services may include inaccuracies or typographical errors. Changes are periodically made to the  sites/services and to the information therein.  and/or its respective suppliers may make improvements and/or changes in the  sites/services at any time. Advice received via the  sites/services should not be relied upon for personal, medical, legal or financial decisions and you should consult an appropriate professional for specific advice tailored to your situation.</div>

            <div className='mg-t-15'>Its respective suppliers make no representations about the suitability, reliability, availability, timeliness, lack of viruses or other harmful components and accuracy of the information, software, products, services and related graphics contained within the sites/services for any purpose. All such information, software, products, services and related graphics are provided "as is" without warranty of any kind.  and/or its respective suppliers hereby disclaim all warranties and conditions with regard to this information, software, products, services and related graphics, including all implied warranties and conditions of merchantability, fitness for a particular purpose, workmanlike effort, title and non-infringement.</div>

            <div className='mg-t-15'>This site may contain links to other web sites operated by third parties ("linked sites"). You acknowledge that, when you click on a link to visit a linked site, a frame may appear that contains the  logo, advertisements and/or other content selected by . You acknowledge that  and its sponsors neither endorse nor are affiliated with the linked site and are not responsible for any content of any linked site or any link contained in a link site, or any changes or updates to such sites. You also acknowledge that  is providing these links to you only as a convenience.</div>

          </div>

        </div>
      </div>
    </div >
  );
}

export default About;